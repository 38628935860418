// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

import React from "react";
import ReactDOM from "react-dom";
// import "bulma/css/bulma.min.css";
import App from "./App";

// import { UserProvider } from "./context/UserContext";
// import {UserTokenProvider, UserPermissionProvider} from "./context/TokenContext"

ReactDOM.render(
  // <UserTokenProvider>
  //   <UserPermissionProvider>
    // <App />
  //   </UserPermissionProvider>
  //</UserTokenProvider> 

    <App />
  ,
  document.getElementById("root")
);