import React, { useState} from "react";
import "./css/ai_model_versionPopup.css";
import { useHistory } from 'react-router-dom';
import {Box, TextField, Button} from "@mui/material";
import { Close } from '@material-ui/icons';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import * as backend_config from '../../config/backend'
import { useLanguage } from "../../context/TokenContext";



export function AddAIModelVersion(props) {

    let {langPack} = useLanguage();
    langPack = langPack["ai_model_version"];

    const {token, trigger, setTrigger, ai_model_id, refreshFunc} = props;

    const [name, setName] = useState('');
    // const [description, setDescription] = useState(null);
    const [trainFile, setTrainFile] = useState(null);
    const [devFile, setDevFile] = useState(null);
    const [testFile, setTestFile] = useState(null);
    const [ruleFile, setRuleFile] = useState(null);

    // const handleChangeLoaiTrangThai = (event) => {
    //     setLoaiTrangThai(event.target.value);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(trainFile);
        let formData = new FormData();

        formData.append('name', name);
        // formData.append('description', description);
        formData.append('ai_model_id', ai_model_id);

		formData.append('train_file', trainFile, trainFile.name);
        if (devFile !== null && devFile !== undefined) formData.append('dev_file', devFile, devFile.name);
        if (testFile !== null && testFile !== undefined) formData.append('test_file', testFile, testFile.name);
        if (ruleFile !== null && ruleFile !== undefined) formData.append('rule_file', ruleFile, ruleFile.name);

        // console.log(formData);
        
        // const body = JSON.stringify({
        //     name: name,
        //     description: description
        // });
        const body = formData;

        backend_config.makeRequest("POST", 
            backend_config.AI_MODEL_VERSION_POST_CREATE, 
            token,
            body,
            null,
            true
        )
        .then((response) => {
            if (response.ok){
                response.json().then((response_json) => {
                    setTrigger(false);
                    refreshFunc();
                    console.log(response_json);
                })
            }
            else {
                response.text().then((text) => {
                    alert(`Error with message: ${text}`);
                })
            }
        })

    };

    return (trigger) ? (
        <div className="ai_model_version-popup-main">
            <form className="ai_model_version-popup-inner" onSubmit={handleSubmit}>
                <Close className="close-btn" style={{cursor: 'pointer'}} onClick={() => setTrigger(false)}/>
                <div className="create-model">
                    <h4>{langPack["Add new version"]}</h4>
                    <div className="ai_model-add-item">
                        <label>{langPack["Version Name"]}</label>
                        <input
                        className="ai_model-add-input"
                        type="text"
                        // placeholder="Version Name"
                        style={{ height: "25px" }}
                        onChange={(e) => setName(e.target.value)}
                        required
                        />
                    </div>
                    {/* <div className="ai_model-add-item">
                        <label>{langPack["Description"]}</label>
                        <textarea
                        className="ai_model-add-input"
                        type="text"
                        placeholder="Description"
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ height: "40px" }}
                        />
                    </div> */}
                    <h5>{langPack["Data"]}</h5>
                    <div className="ai_model-add-item">
                        <label>{langPack["Train data"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="train_data" 
                            accept="*" style={{content: "dataxxxxx"}} required 
                            onChange={(event)=>{setTrainFile(event.target.files[0])}}/>
                    </div>
                    <div className="ai_model-add-item">
                        <label>{langPack["Dev data"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="dev_data" 
                            accept="*" 
                            onChange={(event)=>{setDevFile(event.target.files[0])}}/>
                    </div>
                    <div className="ai_model-add-item">
                        <label>{langPack["Test data (benchmark only)"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="test_data" 
                            accept="*"
                            onChange={(event)=>{setTestFile(event.target.files[0])}}/>
                    </div>
                    
                    <h5>{langPack["Rules"]}</h5>
                    <div className="ai_model-add-item">
                        <label>{langPack["Upload Rules"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="rule_file" 
                            accept="*"
                            onChange={(event)=>{setRuleFile(event.target.files[0])}}/>
                    </div>
                    <button type='submit' style={{cursor: 'pointer'}}>{langPack["Train model"]}</button>
                </div>
  
            </form>
        </div>
    ) : "";
}



export function Download(props) {
    let {langPack} = useLanguage();
    langPack = langPack["ai_model_version"];

    const {id, mark, token, isAvaiable} = props;
    // backend_config.AI_MODEL_VERSION_DELETE_BY_ID.replace('{ai_model_version_id}', id),
    // Are you sure to delete version "{name}" ?

    const [downloadUrl, setDownloadUrl] = useState("");

    const download = () =>{
        if (downloadUrl !== "")
            return backend_config.makeRequest("GET", backend_config.COMMON_GET_DOWNLOAD_TOKEN, token)
            .then((response) => response.json())
            .then((download_token) => {
                window.open(downloadUrl.replace(
                    "{ai_model_version_id}", id).replace("{download_token}", download_token));
            })
    }
    
  
    const handleSubmit = (e) => {
        e.preventDefault();
        props.setTrigger(false);
        download();
  
    };
  
    return (props.trigger && mark === id) ? (
            <form className="delete-popup-main" onSubmit={handleSubmit}>  
                <Close style={{cursor: "pointer"}} onClick={() => props.setTrigger(false)}/>
                    <h3 className='delete-title'>{langPack["Choose file for downloading:"]}</h3>

                        <InputLabel id="demo-simple-select-label">File</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={downloadUrl}
                            // label="File"
                            onChange={(e) => {setDownloadUrl(e.target.value)}}
                        >
                            <MenuItem value={backend_config.AI_MODEL_VERSION_DOWNLOAD_TRAINING_RESULT} disabled={!isAvaiable?.training_result}>{langPack["Training Result"]}</MenuItem>
                            <MenuItem value={backend_config.AI_MODEL_VERSION_DOWNLOAD_MODEL} disabled={!isAvaiable?.model}>{langPack["Model"]}</MenuItem>
                            <MenuItem value={backend_config.AI_MODEL_VERSION_DOWNLOAD_TRAINING_LOG} disabled={!isAvaiable?.training_log}>{langPack["Training Log"]}</MenuItem>
                        </Select>
                        <Button style={{
                          // display: 'flex',
                            backgroundColor: 'blue',
                            cursor: 'pointer',
                            color: 'white',
                            fontSize: '16px',
                            textTransform: 'inherit',
                            marginLeft: '20px',
                            }}
                            // onClick={() => props.setTrigger(false)}
                            type="submit"
                            >
                            {langPack["Download"]}
                        </Button>
                        
                    
            </form>
    ) : "";
  }



export function DetailAIModelVersion(props) {
    const {token, trigger, setTrigger, ai_model_version, categories, refreshFunc} = props;

    // const [name, setName] = useState('');
    // const [description, setDescription] = useState(null);
    // const [trainFile, setTrainFile] = useState(null);
    // const [devFile, setDevFile] = useState(null);
    // const [testFile, setTestFile] = useState(null);
    // const [ruleFile, setRuleFile] = useState(null);

    // // const handleChangeLoaiTrangThai = (event) => {
    // //     setLoaiTrangThai(event.target.value);
    // // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // console.log(trainFile);
    //     let formData = new FormData();

    //     formData.append('name', name);
    //     formData.append('description', description);
    //     formData.append('company_id', company_id);

	// 	formData.append('train_file', trainFile, trainFile.name);
    //     if (devFile !== null) formData.append('dev_file', devFile, devFile.name);
    //     if (testFile !== null) formData.append('test_file', testFile, testFile.name);

    //     // console.log(formData);
        
    //     // const body = JSON.stringify({
    //     //     name: name,
    //     //     description: description
    //     // });
    //     const body = formData;

    //     backend_config.makeRequest("POST", 
    //         backend_config.AI_MODEL_POST_CREATE, 
    //         token,
    //         body,
    //         null,
    //         true
    //     )
    //     .then((response) => {
    //         if (response.ok){
    //             response.json().then((response_json) => {
    //                 setTrigger(false);
    //                 refreshFunc();
    //                 console.log(response_json);
    //             })
    //         }
    //         else {
    //             response.text().then((text) => {
    //                 console.log(text);
    //             })
    //         }
    //     })

    // };

    return (trigger) ? (
        <div className="ai_model_version-detail-popup-main">
            <form className="ai_model_version-popup-inner">
                <Close className="close-btn" style={{cursor: 'pointer'}} onClick={() => setTrigger(false)}/>
                <div className="model">
                    <div className="model-item">
                        <label>
                            Name
                        </label>
                        <input 
                            type="text"
                            className="model-item-input"
                            value={ai_model_version.name}
                            disabled
                            style={{
                                height: '25px', 
                            }}
                        />
                        <label>
                            Status
                        </label>
                        <input 
                            type="text"
                            className="model-item-input"
                            value={ai_model_version.training_result?.status}
                            style={{
                                height: '25px', 
                            }}
                            disabled
                        />
                    </div>
                    {/* <div className="model-item">
                        <label>
                            Description
                        </label>
                        <textarea 
                            value="Description 1"
                            style={{height: '40px'}}
                            disabled
                        />
                    </div> */}
                    <div className="model-item">
                        <label>
                            Categories
                        </label>
                        <input 
                            type="text"
                            // className="model-item-input"
                            value={categories}
                            disabled
                            style={{
                                height: '25px', 
                            }}
                        />
                    </div>
                    <div className="model-item">
                        <label>
                            Last training time
                        </label>
                        <input 
                            type="text"
                            className="model-item-input"
                            value="2022-03-08 07:00:00"
                            disabled
                            style={{
                                height: '25px', 
                            }}
                        />
                    </div>
                    <div className="model-item">
                        <label>
                            Training data
                        </label>
                        <button className="model-item-button">Click here to download</button>
                        <button className="model-item-button">Retrain Model</button>
                    </div>
                    <div className="model-item">
                        <label>
                        </label>
                        <p>Some statistics for dataset here</p>
                    </div>
                    <div className="model-item">
                        <label>
                            Training Message
                        </label>
                        <input 
                            type="text"
                            className="model-item-input"
                            value="Model has been trained successfully"
                            disabled
                            style={{
                                height: '25px', 
                            }}
                        />
                    </div>
                    <div className="model-item">
                        <label>
                        </label>
                        <p>Some statistics for training model: training time</p>
                    </div>
                    <div className="model-item">
                        <label>
                            Training Result
                        </label>
                        <input 
                            type="text"
                            className="model-item-input"
                            value="Accuracy: 80%"
                            disabled
                            style={{
                                height: '25px', 
                            }}
                        />
                        <button className="model-item-button">Download prediction for test set</button>
                    </div>
                    <div className="model-item">
                        <label>
                            Rules File
                        </label>
                        <input 
                            type="text"
                            className="model-item-input"
                            value="Some statistics for rules here"
                            disabled
                            style={{
                                height: '25px', 
                            }}
                        />
                    </div>
                    <div className="model-item">
                        <label>
                        </label>
                        <button className="model-item-button">Click here to download</button>
                    </div>
                    <div className="model-item">
                        <label>
                        </label>
                        <button className="model-item-button">Click here to update rules file</button>
                    </div>
                    <div className="model-item">
                        <p>Download models: choose a version from list:</p>
                    </div>
                </div>
  
            </form>
        </div>
    ) : "";
}



