import { useState, useEffect, createContext, useContext } from 'react';
import {useCookies} from "react-cookie";
import {makeRequest, BACKEND_URL_TOKEN_CHECK} from '../config/backend'
import * as config from '../config/index'
import decodeJwt from 'jwt-decode';
// import { UserContext } from './UserContext';
// import { useToken } from './useToken';


const localStorageToken = "MarkitoneToken";
const localStorageUserPermissions = "MarkitonePermission";
const cookieToken = "MarkitoneToken";


export function useToken() {
  const [cookies, setCookie, removeCookie] = useCookies([cookieToken]);
  const getToken = () => {
    // const tokenString = localStorage.getItem(localStorageToken);
    const tokenString = cookies[cookieToken];
    const userToken = tokenString;
    return userToken === undefined ? null: userToken
  };

  const [token, setToken] = useState(getToken());
  // console.log(token);

  const saveToken = userToken => {
    // console.log(userToken)
    
    if (!userToken || userToken === null) removeCookie(cookieToken, { path: '/' });
    else setCookie(cookieToken, userToken, { path: '/' });
    // console.log(userToken)
    setToken(userToken);
    // console.log(cookies[cookieToken])
  };


  useEffect(() => {
    const checkToken = async () => {
      // const response = await makeRequest("GET", BACKEND_URL_TOKEN_CHECK, token);
      // if (!response.ok){
      //   // console.log(response);
      //   saveToken(null);
      // }
    };
    checkToken();
  }, []);


  return {
    token: token,
    setToken: saveToken
  }
}



export function useUserInfo() {
  const [cookies, setCookie, removeCookie] = useCookies([cookieToken]);
  const allUserPermissions = new Set(['not_active', 'user', 'admin']);
  const getToken = () => {
    // const tokenString = localStorage.getItem(localStorageToken);
    const tokenString = cookies[cookieToken];
    const userToken = tokenString;
    return userToken === undefined ? null: userToken
  };

  const checkToken = (decodedToken) => {
    const exp = (decodedToken === null) ? (new Date()).getTime()/1000 - 10000 : decodedToken.exp;
    // console.log(exp, (new Date()).getTime()/1000, decodedToken, !allUserPermissions.has(userPermission))
    // console.log(token, decodedToken, exp < (new Date()).getTime(), !allUserPermissions.has(userPermission));
    if (exp < (new Date()).getTime()/1000 || !allUserPermissions.has(decodedToken?.permissions)) {
      // console.log(exp, (new Date()).getTime()/1000, !allUserPermissions.has(userPermission), decodedToken)
      return false
    }
    return true
      
  }; 

  const decodeToken = () => {
    const token = getToken();
    // console.log(token)
    let decodedToken = null;
    try{
      if (token !== null || token !== undefined) decodedToken = decodeJwt(token);
      // console.log(decodedToken)
      // console.log(checkToken(decodedToken))
      if (!checkToken(decodedToken)) decodedToken = null;
    } catch(e) {}

    return decodedToken;
  };


  

  let decodedToken = decodeToken();
  const [userPermission, setUserPermission] = useState(decodedToken === null ? null: decodedToken.permissions);
  const [user, setUser] = useState({name: decodedToken === null ? null: decodedToken.sub});


  // useEffect(() => {
  //   const checkToken = () => {
  //       const exp = (decodedToken === null) ? (new Date()).getTime()/1000 - 10000 : decodedToken.exp;
  //       // console.log(exp, (new Date()).getTime()/1000, decodedToken)
  //       // console.log(token, decodedToken, exp < (new Date()).getTime(), !allUserPermissions.has(userPermission));
  //       if (exp < (new Date()).getTime()/1000 || !allUserPermissions.has(userPermission)) {
  //         return false
  //       }
  //       return true
  //       // console.log(userPermission);
  //   };
  //   // console.log(token);
  //   const is_valid = checkToken();
  //   // console.log(userPermission, is_valid, decodeToken());
  //   if (!is_valid){
  //       decodedToken = null;
  //       setUserPermission(null);
  //       setUser(null);
  //   }
  //   // console.log(decodedToken);
  //   // console.log(is_valid);
  // }, []);

  return {
    userPermission: userPermission,
    setUserPermission: setUserPermission,
    user: user,
    setUser: setUser
  }
}



export function useLanguage() {
  const langTokenName = "MarkitoneLang";
  const listLang = ["en", "ja"];
  let defaultLang = config.LANG;
  if (!listLang.includes(defaultLang)) defaultLang = listLang[0];

  const [cookies, setCookie, removeCookie] = useCookies([langTokenName]);

  const getLangToken = () => {
    // const tokenString = localStorage.getItem(localStorageToken);
    const tokenString = cookies[langTokenName];
    let lang = tokenString === undefined ? defaultLang: tokenString;
    if (!(listLang.includes(lang)) || config.USE_DEFAULT_LANG) lang = defaultLang;
    

    return lang
  };


  const [langToken, setLangToken] = useState(getLangToken());
  const [langPack, setLangPack] = useState(require(`../lang/${langToken}.json`));


  const saveLangToken = (userToken_) => {
    var userToken = userToken_;
    if (!userToken) removeCookie(langTokenName, { path: '/' });
    else {
      if (!(listLang.includes(userToken))) userToken = defaultLang
      setCookie(langTokenName, userToken, { path: '/' });
    }
    // console.log(userToken)
    setLangToken(userToken);
  };


  useEffect(() => {
    setLangPack(require(`../lang/${langToken}.json`));
  }, [langToken]);

  return {
    langToken: langToken,
    setLangToken: saveLangToken,
    langPack: langPack
  }
}




// export function useToken() {
//   const [cookies, setCookie, removeCookie] = useCookies([cookieToken]);
//   const getToken = () => {
//     // const tokenString = localStorage.getItem(localStorageToken);
//     const tokenString = cookies[cookieToken];
//     const userToken = tokenString;
//     return userToken === undefined ? null: userToken
//   };

//   const [token, setToken] = useState(getToken());
//   // console.log(token);

//   const saveToken = userToken => {
    
//     if (!userToken) removeCookie(cookieToken);
//     else setCookie(cookieToken, userToken, { path: '/' });
//     // console.log(userToken)
//     setToken(userToken);
//   };

//   const decodeToken = () => {
//     try{
//       if (token !== null || token !== undefined) return  decodeJwt(token);
//       return null
//     } catch(e) {}
//     return null
//   };


//   const allUserPermissions = new Set(['not_active', 'user', 'admin']);

//   const [decodedToken, setDecodedToken] = useState(decodeToken());
//   const [userPermission, setUserPermission] = useState(decodedToken === null ? null: decodedToken.permissions);
//   const [user, setUser] = useState({name: decodedToken === null ? null: decodedToken.sub});


//   useEffect(() => {
//     const checkToken = async () => {
//       const response = await makeRequest("GET", BACKEND_URL_TOKEN_CHECK, token);
//       if (!response.ok){
//         // console.log(response);
//         saveToken(null);
//         setDecodedToken(null);
//         setUserPermission(null);
//         setUser(null);
//       }
//     };
//     checkToken();
//   }, []);


//   // useEffect(() => {
//   //   const checkToken = () => {
//   //       const exp = (decodedToken === null) ? (new Date()).getTime() - 10000 : decodedToken.exp;
//   //       // console.log(exp, (new Date()).getTime())
//   //       console.log(token, decodedToken, exp < (new Date()).getTime(), !allUserPermissions.has(userPermission));
//   //       if (exp < (new Date()).getTime() || !allUserPermissions.has(userPermission)) {
//   //         return false
//   //       }
//   //       return true
//   //       // console.log(userPermission);
//   //   };
//   //   // console.log(token);
//   //   const is_valid = checkToken();
//   //   // console.log(token, decodeToken(), is_valid);
//   //   if (!is_valid){
//   //       setDecodedToken(null);
//   //       setUserPermission(null);
//   //       saveToken(null);
//   //       setUser(null);
//   //   }
//   //   // console.log(decodedToken);
//   //   // console.log(is_valid);
//   // }, []);

//   return {
//     token: token,
//     setToken: saveToken, 
//     userPermission: userPermission,
//     setUserPermission: setUserPermission,
//     user: user,
//     setUser: setUser
//   }
// }