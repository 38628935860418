import { useState } from "react";
import {useToken} from '../context/TokenContext';
import { BACKEND_URL } from "./backend_url";


export const BACKEND_URL_TOKEN = BACKEND_URL + '/token';
export const BACKEND_URL_TOKEN_CHECK = BACKEND_URL + '/user/me';

export const COMMON_GET_DOWNLOAD_TOKEN = BACKEND_URL + '/common/get_download_token';



export const USER_GET_LIST_API = BACKEND_URL + '/user/list';
export const USER_GET_CURRENT_API = BACKEND_URL + '/user/me';
export const USER_GET_BY_ID_API = BACKEND_URL + '/user/id/{id}';


export const COMPANY_GET_LIST_API = BACKEND_URL + '/company/list';
export const COMPANY_GET_BY_ID_API = BACKEND_URL + '/company/id/{company_id}';
export const COMPANY_POST_CREATE = BACKEND_URL + '/company/create';
export const COMPANY_DELETE_BY_ID = BACKEND_URL + '/company/delete?company_id={company_id}';


export const AI_MODEL_GET_LIST_BY_COMPANY_API = BACKEND_URL + '/ai_model/list?company_id={company_id}';
export const AI_MODEL_GET_BY_ID_API = BACKEND_URL + '/ai_model/id/{ai_model_id}';
export const AI_MODEL_POST_CREATE = BACKEND_URL + '/ai_model/create';
export const AI_MODEL_POST_TEST_UPLOAD_FILE = BACKEND_URL + '/ai_model/test/upload_file';
export const AI_MODEL_DELETE_BY_ID = BACKEND_URL + '/ai_model/delete?ai_model_id={ai_model_id}';


export const AI_MODEL_VERSION_GET_LIST_BY_AI_MODEL_API = BACKEND_URL + '/ai_model/version_/list?ai_model_id={ai_model_id}';
export const AI_MODEL_VERSION_POST_CREATE = BACKEND_URL + '/ai_model/version_/create';
export const AI_MODEL_VERSION_DELETE_BY_ID = BACKEND_URL + '/ai_model/version_/delete?ai_model_version_id={ai_model_version_id}';
export const AI_MODEL_VERSION_DOWNLOAD_TRAINING_RESULT = BACKEND_URL + '/ai_model/version_/training_result/download?ai_model_version_id={ai_model_version_id}&download_token={download_token}';
export const AI_MODEL_VERSION_DOWNLOAD_MODEL = BACKEND_URL + '/ai_model/version_/training_result/download_model?ai_model_version_id={ai_model_version_id}&download_token={download_token}';
export const AI_MODEL_VERSION_DOWNLOAD_TRAINING_LOG = BACKEND_URL + '/ai_model/version_/training_result/download_training_log?ai_model_version_id={ai_model_version_id}&download_token={download_token}';



export const makeRequest = async (method, url, token, body=null, contentType="application/json", delete_contentType=false) => {
  let requestOptions = {
    method: method,
    headers: {
      "Content-Type": contentType,
      Authorization: "Bearer " + token,
    },
  };
  if (body !== null) requestOptions.body = body;
  if (delete_contentType) delete requestOptions.headers["Content-Type"];
  // console.log(token);

  return fetch(url, requestOptions);
};
