import React,  { useState } from "react";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import "./dashboard.css";

import { UserContext} from "../../context/UserContext";
import { useToken } from "../../context/TokenContext";
// import { useToken, useUserPermission } from "../../context/useToken";

import FeaturedInfo from "../featuredInfo/FeaturedInfo";
import * as backend_config from "../../config/backend"



const Dashboard = (props) => {
  // const [token, setToken] = useContext(UserContext);
  let history = useHistory();

  // const {token, setToken} = useToken();
  const token = props.token;
  // console.log(token);
  
  const getCurrentUser = async () => {
    const response = await backend_config.makeRequest("GET", backend_config.BACKEND_URL_TOKEN_CHECK, token);
    // const response = await fetch(backend_config.BACKEND_URL_TOKEN_CHECK, requestOptions);
    if (response.ok) {
      const data = await response.json();
      console.log(data);
    }
    else{
      console.log("----------not ok");
      console.log(response);
      history.push("/");
    }
  };

  const getUserList = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    // console.log(token);

    const response = await fetch(backend_config.USER_GET_LIST_API + "?limit=10&offset=0", requestOptions);
    if (response.ok) {
      const data = await response.json();
      console.log(data);
    }
    else{
      console.log("----------not ok");
      console.log(response);
      history.push("/");
    }
  };

  const getUserById = async (id=1) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    // console.log(token);

    const response = await fetch(backend_config.USER_GET_CURRENT_API.replace('{id}', id.toString()), requestOptions);
    if (response.ok) {
      const data = await response.json();
      console.log(data);
    }
    else{
      console.log("----------not ok");
      console.log(response);
      history.push("/");
    }
  };



  return (
    <div className="dashboard">
      <h3 className="pageTitle">Welcome Admin!</h3>
      {/* <FeaturedInfo /> */}
      <button onClick={() =>{ history.push("/company")}}>Company</button>
      <button onClick={getCurrentUser}>getCurrentUser</button>
      <button onClick={getUserList}>getUserList</button>
      <button onClick={getUserById}>getUserById</button>
    </div>
    );
};

export default Dashboard;