import {React, useState} from 'react'
import "./topbar.css"
// import Popup from 'reactjs-popup';

import { NotificationsNone, Language, Settings, Search } from '@material-ui/icons';
import Logo from '../../img/markitone_logo_cc.svg';
import { useHistory } from 'react-router-dom';
import { useToken } from '../../context/TokenContext';
import { useLanguage } from "../../context/TokenContext";




function UserPopup(props){
    const handleLogoutClick = props.handleLogoutClick;
    let {langPack} = useLanguage();
    langPack = langPack["topbar"];
    
    return (props.trigger) ? (
        <div className='popup-main'>
            <div className='popup-inner'>
            <button onClick={handleLogoutClick} className='popup-button'>{langPack["Logout"]}</button>
            </div>
        </div>
    ) : "";
};



export default function Topbar({user, setToken}) {
    let history = useHistory();
    const [userButtonPopup, setUserButtonPopup] = useState(false);
    let {langPack, langToken, setLangToken} = useLanguage();
    

    const handleLogoutClick = () => {
        setToken(null);
        history.push('/login');
    } 

    return (
        <div className='topbar'>  
            <div className="topbarWrapper">
                <div className="topLeft">
                    <a href='/'>
                        <img src={Logo} alt='' className='logoIcon'/>
                    </a>
                    {/* <div className='page-title-box'>
                        <h3>Markitone</h3>
                    </div> */}
                </div>
                {/* <button onClick={handleLogoutClick}>Logout</button>  */}
                <ul className="topRight">
                    <li className='topbarIconContainer'>
                    <select defaultValue={langToken} name="language-picker-select" id="language-picker-select" onChange={(e) => {setLangToken(e.target.value); window.location.reload()}}>
                        <option value="ja">日本語</option>
                        <option value="en">English</option>
                        {/* <!-- other language options --> */}
                    </select>
                    </li>
                    {/* <li className='topbarIconContainer'>
                        <div className="dropdown-toggle nav-link">
                            <NotificationsNone/>
                            <span className='topIconBadge'>2</span>
                        </div>
                    </li>
                    <li className='topbarIconContainer'>
                        <Language />
                        <span className='topIconBadge'>2</span>
                    </li>
                    <li className='topbarIconContainer'>
                        <Settings />
                    </li> */}
                    <li>
                        <div className='user-current'>
                            <span className='user-img'>
                                <img onClick={() => setUserButtonPopup(!userButtonPopup)}  src="https://www.w3schools.com/howto/img_avatar2.png" alt="" className="topAvatar" />
                                <span className="status online"></span>
                            </span>    
                            <UserPopup trigger={userButtonPopup} setTrigger={setUserButtonPopup} handleLogoutClick={handleLogoutClick}></UserPopup>
                            {/* <Popup modal trigger={userButtonPopup} >
                                <button onClick={handleLogoutClick} className='popup-button'>Logout</button>
                            </Popup> */}
                            <span className='username'>{user?.name}</span>
                            
                        </div>
                        
                    </li>
                    
                    
                </ul>
            </div>
            
        </div>
    )
}