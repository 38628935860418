import React from 'react';

import { BrowserRouter, Route , Switch } from 'react-router-dom';
import './App.css';

// import Dashboard from './components/dashboard/dashboard';
import {CompanyList, Company} from './components/company/company';
import { AIModelList, AIModel } from './components/ai_model/ai_model';
import Login from './components/login/login';
// import User from './components/pages/user/User';
// import UserList from './components/pages/userList/UserList';
import AdminRoute from './routes/admin/AdminRoute';
import UserRoute from './routes/user/UserRoute'

import UnknownPage from './components/default/UnknownPage';

// "Hello, {name}, are you feeling {adjective}?".formatUnicorn({name:"Gabriel", adjective: "OK"});




const App = () => {
  return (
    <>
      {/* <Header title={message} /> */}
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <UserRoute exact path="/" component={CompanyList} />
            <UserRoute exact path="/company" component={CompanyList} />
            <UserRoute exact path="/company/:companyId" component={Company} />
            <UserRoute exact path="/ai_model/:ai_modelId" component={AIModel} />
            <Route component={UnknownPage} />
            {/* <AdminRoute exact path="/dashboard" component={Dashboard} />
            <AdminRoute exact path="/dashboard/users" component={UserList} />
            <AdminRoute path="/dashboard/user/:userId" component={User} /> */}
          </Switch>
        </BrowserRouter>        
      </div>
    </>
  );
  }
  

export default App;