import React, { useState} from "react";
import "./css/ai_modelPopup.css";
import { useHistory } from 'react-router-dom';
import {Box, TextField, MenuItem, Select, Button} from "@mui/material";
import { Close } from '@material-ui/icons';

import * as backend_config from '../../config/backend'

import { useLanguage } from "../../context/TokenContext";


export function AddAIModel(props) {

    let {langPack} = useLanguage();
    langPack = langPack["ai_model"];

    const {token, trigger, setTrigger, company_id, refreshFunc} = props;

    const [name, setName] = useState('');
    const [description, setDescription] = useState(null);
    const [trainFile, setTrainFile] = useState(null);
    const [devFile, setDevFile] = useState(null);
    const [testFile, setTestFile] = useState(null);
    const [ruleFile, setRuleFile] = useState(null);

    // const handleChangeLoaiTrangThai = (event) => {
    //     setLoaiTrangThai(event.target.value);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(trainFile);
        let formData = new FormData();

        formData.append('name', name);
        formData.append('description', description);
        formData.append('company_id', company_id);

		formData.append('train_file', trainFile, trainFile.name);
        if (devFile !== null && devFile !== undefined) formData.append('dev_file', devFile, devFile.name);
        if (testFile !== null && testFile !== undefined) formData.append('test_file', testFile, testFile.name);
        if (ruleFile !== null && ruleFile !== undefined) formData.append('rule_file', ruleFile, ruleFile.name);

        // console.log(formData);
        
        // const body = JSON.stringify({
        //     name: name,
        //     description: description
        // });
        const body = formData;

        backend_config.makeRequest("POST", 
            backend_config.AI_MODEL_POST_CREATE, 
            token,
            body,
            null,
            true
        )
        .then((response) => {
            if (response.ok){
                response.json().then((response_json) => {
                    setTrigger(false);
                    refreshFunc();
                    // console.log(response_json);
                })
            }
            else {
                response.text().then((text) => {
                    alert(`Error with message: ${text}`);
                })
            }
        })

    };

    return (trigger) ? (
        <div className="add-ai_model-popup-main">
            <form className="add-ai_model-popup-inner" onSubmit={handleSubmit}>
                <Close className="close-btn" style={{cursor: 'pointer'}} onClick={() => setTrigger(false)}/>
                <div className="create-model">
                    <h4>{langPack["Create Model"]}</h4>
                    <div className="ai_model-add-item">
                        <label>{langPack["Model Name"]}</label>
                        <input
                        className="ai_model-add-input"
                        type="text"
                        // placeholder="Model Name"
                        style={{ height: "25px" }}
                        onChange={(e) => setName(e.target.value)}
                        required
                        />
                    </div>
                    <div className="ai_model-add-item">
                        <label>{langPack["Description"]}</label>
                        <textarea
                        className="ai_model-add-input"
                        type="text"
                        // placeholder="Description"
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ height: "40px" }}
                        />
                    </div>
                    <h5>{langPack["Data"]}</h5>
                    <div className="ai_model-add-item">
                        <label>{langPack["Train data"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="train_data" 
                            accept="*" style={{content: "dataxxxxx"}} required 
                            onChange={(event)=>{setTrainFile(event.target.files[0])}}/>
                    </div>
                    <div className="ai_model-add-item">
                        <label>{langPack["Dev data"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="dev_data" 
                            accept="*" 
                            onChange={(event)=>{setDevFile(event.target.files[0])}}/>
                    </div>
                    <div className="ai_model-add-item">
                        <label>{langPack["Test data (benchmark only)"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="test_data" 
                            accept="*"
                            onChange={(event)=>{setTestFile(event.target.files[0])}}/>
                    </div>
                    
                    <h5>{langPack["Rules"]}</h5>
                    <div className="ai_model-add-item">
                        <label>{langPack["Upload Rule"]}</label>
                        <input className="ai_model-add-item-input-file-data" type="file" name="rule_file" 
                            accept="*"
                            onChange={(event)=>{setRuleFile(event.target.files[0])}}/>
                    </div>
                    <button type='submit' style={{cursor: 'pointer'}}>{langPack["Train model"]}</button>
                </div>
  
            </form>
        </div>
    ) : "";
}



