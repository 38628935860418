import React, { useState, useEffect} from "react";
import "./companyPopup.css";
import { useHistory } from 'react-router-dom';
import {Box, FormControl, MenuItem, Select, Button} from "@mui/material";
import { Close } from '@material-ui/icons';

import * as backend_config from '../../config/backend'

import { useLanguage } from "../../context/TokenContext";


export function AddCompany(props) {

    let {langPack} = useLanguage();
    langPack = langPack["company"];

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    // const handleChangeLoaiTrangThai = (event) => {
    //     setLoaiTrangThai(event.target.value);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const body = JSON.stringify({
            name: name,
            description: description
        });

        backend_config.makeRequest("POST", 
            backend_config.COMPANY_POST_CREATE, 
            props.token,
            body
        )
        .then((response) => {
            if (response.ok){
                response.json().then((response_json) => {
                    props.setTrigger(false);
                    props.fetchDataToTable();
                })
            }
            else {
                response.text().then((text) => {
                    // console.log(`Duplicate ${name}!`);
                    alert(langPack["Duplicate company name: \"{name}\" !"].replace("{name}", name));
                })
            }
        })

    };

    return (props.trigger) ? (
        <div className="add-company-popup-main">
            <form className="popup-inner" onSubmit={handleSubmit}>
                <Close className="close-btn" onClick={() => props.setTrigger(false)}/>
                <div className='addCompany'>
                    <h5 className='modal-title'>{langPack["Add new company"]}</h5>
                    <div className='modal-body'>
                        <div className='companyAddItem'>
                            <label>
                                {langPack["Name"]}
                                <span className='text-danger' style={{color: 'red'}}>  *</span>
                            </label>
                            <input
                                type="text"
                                className='companyAddInput'
                                id="name" 
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='companyAddItem'>
                            <label>
                                {langPack["Description"]}
                            </label>
                            <input
                                type="text"
                                className='companyAddInput'
                                id="description" 
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal-footer'>
                        {/* <button className='userAddButtonSubmit' type='submit'>Add</button> */}
                        <Button
                        style={{
                            margin: '10px 10px 10px auto',
                            display: 'flex',
                            border: '1px solid #ff9b44',
                            backgroundColor: '#ff9b44',
                            borderRadius: '50px',
                            cursor: 'pointer',
                            color: 'white',
                            fontSize: '16px',
                            textTransform: 'inherit',
                        }}
                        type='submit'
                    >
                        {langPack["Add"]}
                    </Button>
                    </div>
                </div>
            </form>
        </div>
    ) : "";
}



// export function DeleteCompany(props) {
//     const {id, name} = props.company;

//     const setTrigger = (value) => {
//         // let data = Object.assign({}, props.trigger);
//         // data[id] = value;
//         // props.setTrigger(data);
//         props.setTrigger(value);
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault();
        
//         backend_config.makeRequest("DELETE", 
//             backend_config.COMPANY_DELETE_BY_ID.replace('{company_id}', id), 
//             props.token,
//         )
//         .then((response) => {
//             if (response.ok){
//                 response.json().then((response_json) => {
//                     setTrigger(false);
//                     props.fetchDataToTable();
//                 })
//             }
//             else {
//                 response.text().then((text) => {
//                     // console.log(`Duplicate ${name}!`);
//                     alert(`Can not delete company!`);
//                 })
//             }
//         })

//     };
//     useEffect(() => {
//         // console.log(id, name);
//         // console.log(props.markDelete);
//     }, [])

//     return (props.trigger && props.markDelete === id) ? (
//             <form className="delete-company-popup-main" onSubmit={handleSubmit}>  
//                 <Close className="close-btn" onClick={() => setTrigger(false)}/>
//                     <h3 className='delete-title'>Are you sure to delete company "{name}" ?</h3>
//                     <Button style={{
//                             // display: 'flex',
//                             backgroundColor: 'red',
//                             cursor: 'pointer',
//                             color: 'white',
//                             fontSize: '16px',
//                             textTransform: 'inherit',
//                             marginRight: '20px',
//                         }}
//                         type='submit'>
//                         Yes
//                     </Button>
//                     <Button style={{
//                             // display: 'flex',
//                             backgroundColor: 'blue',
//                             cursor: 'pointer',
//                             color: 'white',
//                             fontSize: '16px',
//                             textTransform: 'inherit',
//                             marginLeft: '20px',
//                         }}
//                         onClick={() => setTrigger(false)}>
//                         No
//                     </Button>

//             </form>
//     ) : "";
// }

