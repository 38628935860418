import React,  { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from 'react-router-dom';
import "./css/ai_model.css";
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Add, EditOutlined } from '@material-ui/icons';
import { Button } from '@mui/material';

import { useToken } from "../../context/TokenContext";
// import { useToken, useUserPermission } from "../../context/useToken";

import * as backend_config from "../../config/backend"
import {AddAIModel, DeleteAIModel} from "./ai_modelPopup";
import { AIModelVersionList } from "./ai_model_version";
import { DeletePopup } from "../popup/delete";
import { GridCellExpand } from "../common/renderCellExpand";

import { useLanguage } from "../../context/TokenContext";



export function AIModelList(props) {

    let {langPack} = useLanguage();
    langPack = langPack["ai_model"];

    const token = props.token;
    let company = props.company;
    let history = useHistory();
//   console.log(token);

    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [addAIModelButtonPopup, setAddAIModelButtonPopup] = useState(false);
    const [deleteAIModelButtonPopup, setDeleteAIModelButtonPopup] = useState(false);
    const [markDelete, setMarkDelete] = useState();


    const fetchData = async () =>{
        
        return backend_config.makeRequest("GET", backend_config.AI_MODEL_GET_LIST_BY_COMPANY_API.replace('{company_id}', company?.id), token)
        .then((response) => response.json())
        .then((data) => {return data; })
    }

    const fetchDataToTable = () =>{
        fetchData().then((data) => {
            setTableData(data);
            if (!isLoaded) setIsLoaded(true);
        });
    }


    const columns = [
        // { field: 'ma_nguoi_dung', headerName: 'ID', width: 100 },
        { field: 'name', headerName: langPack["Name"], flex: 1, renderCell: (params)=>{
            return (
                // <div className="companyList">
                    <a href="" onClick={() => {history.push("/ai_model/" + params.row.id)}}>{params.row.name}</a>
                // </div>
            )
        } },
        { 
            field: 'description', 
            headerName: langPack["Description"], 
            flex: 1,
            valueGetter: (params) => {
                return params.row?.description === 'null' ? '': params.row?.description
            }
            // renderCell: (params)=>{

            //     return (
            //         <>
            //             <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
                       
            //         </>
            //       )
            // }
        },
        {
            field: 'create_at',
            headerName: langPack["Create at"],
            flex: 1,
            // renderCell: (params)=>{

            //     return (
            //         <>
            //             <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
                       
            //         </>
            //       )
            // }
        },
        {
            field: 'ai_model_current_version.name',
            headerName: langPack["Current version"],
            flex: 1,
            valueGetter: (params) => params.row?.ai_model_current_version.name
        },
        {
            field: 'training_result.status',
            headerName: langPack["Status"],
            flex: 1,
            valueGetter: (params) => params.row?.ai_model_current_version.training_result?.status
        },
        {
            field:"action",
            headerName: langPack["Action"],
            width: 150,
            renderCell: (params)=>{
                const id = params.row.id;
                return(
                    <>
                        {/* <Link to={"/dashboard/cong-van-di/"+params.row.so_cong_van_di} params={{id: params.row.so_cong_van_di}}>
                            <button className='companyListEdit'>Details</button>
                        </Link> */}
                        <DeleteOutline className='ListDelete' onClick={()=>{setMarkDelete(id); setDeleteAIModelButtonPopup(true)}}>
                        </DeleteOutline>
                        <DeletePopup token={token} id={id} refreshFunc={fetchDataToTable} 
                            url={backend_config.AI_MODEL_DELETE_BY_ID.replace('{ai_model_id}', id)} 
                            message={`Are you sure to delete model ${params.row?.name} ?`}
                            trigger={deleteAIModelButtonPopup} setTrigger={setDeleteAIModelButtonPopup}
                            markDelete={markDelete}
                        >
                        </DeletePopup>
                    </>

                )
            }
        }
    ];

    useEffect(() => {
        fetchDataToTable();
    },[])
    
    return (
        <div className='List'>
            <h2 className='ListTitle'>{langPack["All models of Company {company?.name}"].replace("{company?.name}", company?.name)}</h2>
            <br></br>
            {isLoaded &&
            <div style={{ height: 'calc(90vh - 170px)', width: '100%' }}>
                <DataGrid
                    getRowId={(r) => r.id}
                    rows={tableData}
                    disableSelectionOnClick
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                //   checkboxSelection
                />
            </div>
            }
            
            <Button
                        className='AddButton'
                        style={{
                            margin: '10px 10px 10px auto',
                            display: 'flex',
                            border: '1px solid #ff9b44',
                            backgroundColor: '#ff9b44',
                            borderRadius: '50px',
                            cursor: 'pointer',
                            color: 'white',
                            fontSize: '16px',
                            textTransform: 'inherit',
                        }}
                        startIcon={<Add/>}
                        onClick={() => setAddAIModelButtonPopup(true)}
                    >
                        {langPack["Add Model"]}
                    </Button>
          <AddAIModel token={token} company_id={company?.id} refreshFunc={fetchDataToTable} 
            trigger={addAIModelButtonPopup} setTrigger={setAddAIModelButtonPopup}>
          </AddAIModel>
        </div>
    )
}


export function AIModel(props) {

    let {langPack} = useLanguage();
    let history = useHistory();

    const token = props.token;
    const {ai_modelId} = useParams();
    const [buttonPopup, setButtonPopup] = useState(false);
    const [ai_model, setAIModel] = useState(null);

    
    const fetchAIModelInfo = async () =>{
        return backend_config.makeRequest("GET", backend_config.AI_MODEL_GET_BY_ID_API.replace('{ai_model_id}', ai_modelId), token)
        .then((response) => response.json())
        .then((data) => { return data})
    }
    
    
    useEffect(() => {
        fetchAIModelInfo().then((data) => {setAIModel(data)});
    }, [])
      
    return ai_model !== null ? (
        <div className='List'>
            <div>
                <a href="" onClick={() => {history.push('/')}} style={{"display": "inline-block"}}>{langPack["common"]["Home"]}</a> 
                <p style={{"display": "inline-block"}}>{"  >>  "}</p>
                <a href="" onClick={() => {history.push(`/company/${ai_model.company_id}`)}} style={{"display": "inline-block"}}>{langPack["common"]["Company"]}</a>
                <p style={{"display": "inline-block"}}>{"  >>  "}</p>
                <a href="" style={{"display": "inline-block"}}>{langPack["common"]["Model"]}</a>
            </div>
              <AIModelVersionList token={token} ai_model={ai_model}></AIModelVersionList>

        </div>
    ) : <div className='List'></div>
}


