import React,  { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from 'react-router-dom';
import "./company.css";
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Add, EditOutlined } from '@material-ui/icons';
import { Button } from '@mui/material';

import { useToken } from "../../context/TokenContext";
// import { useToken, useUserPermission } from "../../context/useToken";

import * as backend_config from "../../config/backend"
import {AddCompany, DeleteCompany} from "./companyPopup";
import {AIModelList} from '../ai_model/ai_model';
import { DeletePopup } from "../popup/delete";
import { GridCellExpand } from "../common/renderCellExpand";


import { useLanguage } from "../../context/TokenContext";


export function CompanyList(props) {
    const token = props.token;
    let history = useHistory();

    let {langPack} = useLanguage();
    langPack = langPack["company"];

    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [addCompanyButtonPopup, setAddCompanyButtonPopup] = useState(false);
    const [deleteCompanyButtonPopup, setDeleteCompanyButtonPopup] = useState(false);
    const [markDelete, setMarkDelete] = useState();


    const fetchData = async () =>{
        return backend_config.makeRequest("GET", backend_config.COMPANY_GET_LIST_API, token)
        .then((response) => response.json())
        .then((data) => { return data;})
    }

    const fetchDataToTable = () =>{
        fetchData().then((data) => {
            setTableData(data);
            if (!isLoaded) setIsLoaded(true);
        });
    }



    const columns = [
        // { field: 'ma_nguoi_dung', headerName: 'ID', width: 100 },
        { field: 'name', headerName: langPack["Name"], flex: 1, renderCell: (params)=>{
            return (
                // <div className="companyList">
                    <a href="" onClick={() => {history.push("/company/" + params.row.id)}}>{params.row.name}</a>
                // </div>
            )
        } },
        { 
            field: 'description', 
            headerName: langPack["Description"], 
            flex: 1,
            // renderCell: (params)=>{

            //     return (
            //         <>
            //             <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
                       
            //         </>
            //       )
            // }
        },
        {
            field: 'create_at',
            headerName: langPack["Create at"],
            flex: 1 
        },
        {
            field: 'num_models',
            headerName: langPack["Number of Models"],
            flex: 1,
            valueGetter: (params) => params.row?.ai_models.length
        },
        {
            field:"action",
            headerName: langPack["Action"],
            width: 150,
            renderCell: (params)=>{
                const id = params.row.id;
                return(
                    <>
                        {/* <Link to={"/dashboard/cong-van-di/"+params.row.so_cong_van_di} params={{id: params.row.so_cong_van_di}}>
                            <button className='companyListEdit'>Details</button>
                        </Link> */}
                        {/* <EditOutlined className='companyListEdit'></EditOutlined> */}
                        <DeleteOutline className='companyListDelete' onClick={()=>{setMarkDelete(id); setDeleteCompanyButtonPopup(true)}}>
                        </DeleteOutline>
                        <DeletePopup token={token} id={id} refreshFunc={fetchDataToTable} 
                            url={backend_config.COMPANY_DELETE_BY_ID.replace('{company_id}', id)} 
                            message={`Are you sure to delete company ${params.row?.name} ?`}
                            trigger={deleteCompanyButtonPopup} setTrigger={setDeleteCompanyButtonPopup}
                            markDelete={markDelete}
                        >
                        </DeletePopup>
                    </>

                )
            }
        }
    ];

    useEffect(() => {
        fetchDataToTable();
    }, [])
    
    return isLoaded ? (
        <div className='companyList'>
            {/* <a href="" onClick={() => {history.push('/')}}>Home</a> */}
            <h2 className='companyListTitle'>{langPack["List of all companies"]}</h2>
            <br></br>
            <div style={{ height: 'calc(90vh - 170px)', width: '100%' }}>
                <DataGrid
                    getRowId={(r) => r.id}
                    rows={tableData}
                    disableSelectionOnClick
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    zeroMinWidth
                //   checkboxSelection
                />
            </div>
            <Button
                        className='companyAddButton'
                        style={{
                            margin: '10px 10px 10px auto',
                            display: 'flex',
                            border: '1px solid #ff9b44',
                            backgroundColor: '#ff9b44',
                            borderRadius: '50px',
                            cursor: 'pointer',
                            color: 'white',
                            fontSize: '16px',
                            textTransform: 'inherit',
                        }}
                        startIcon={<Add/>}
                        onClick={() => setAddCompanyButtonPopup(true)}
            >
                {langPack["Add Company"]}
            </Button>
          <AddCompany token={token} fetchDataToTable={fetchDataToTable} trigger={addCompanyButtonPopup} setTrigger={setAddCompanyButtonPopup}>
          </AddCompany>
        </div>
    ): <div className='companyList'></div>
}


export function Company(props) {

    let {langPack} = useLanguage();
    let history = useHistory();

    const token = props.token;
    const {companyId} = useParams();

    
    const fetchCompanyInfo = async () =>{
        return backend_config.makeRequest("GET", backend_config.COMPANY_GET_BY_ID_API.replace('{company_id}', companyId), token)
        .then((response) => response.json())
        .then((data) => { return data})
    }

    const [companyInfo, setCompanyInfo] = useState(null);
    
    useEffect(() => {
        // console.log('fetch data company')
        fetchCompanyInfo().then((data) => {setCompanyInfo(data)});
    }, [])
      
    return (
        <div className='companyList'>
            <div>
                <a href="" onClick={() => {history.push('/')}} style={{"display": "inline-block"}}>{langPack["common"]["Home"]}</a> 
                <p style={{"display": "inline-block"}}>{"  >>  "}</p>
                <a href="" style={{"display": "inline-block"}}>{langPack["common"]["Company"]}</a>
            </div>
              <br></br>
              {companyInfo !== null &&
                  <AIModelList token={token} company={companyInfo}></AIModelList>
              }

        </div>
    ) 
}
