import React,  { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, Link, useParams } from 'react-router-dom';
import "./css/ai_model.css";
import "../common/css/loading.css"
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Add, EditOutlined } from '@material-ui/icons';
import InfoIcon from '@mui/icons-material/Info';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';

import { useToken } from "../../context/TokenContext";
// import { useToken, useUserPermission } from "../../context/useToken";

import * as backend_config from "../../config/backend"
// import {DeleteAIModelVersion} from './ai_modelPopup'
import { DeletePopup } from "../popup/delete";
import { GridCellExpand } from "../common/renderCellExpand";
import {AddAIModelVersion, DetailAIModelVersion, Download} from "./ai_model_versionPopup";

import { useLanguage } from "../../context/TokenContext";



export function AIModelVersionList(props) {

    let {langPack} = useLanguage();
    langPack = langPack["ai_model_version"];

    const token = props.token;
    let ai_model = props.ai_model;
    // let history = useHistory();
    //   console.log(token);

    const [tableData, setTableData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const [addAIModelVersionButtonPopup, setAddAIModelVersionButtonPopup] = useState(false);
    const [detailAIModelVersionButtonPopup, setDetailAIModelVersionButtonPopup] = useState(false);
    const [deleteAIModelVersionButtonPopup, setDeleteAIModelVersionButtonPopup] = useState(false);
    const [downloadButtonPopup, setDownloadButtonPopup] = useState(false);
    const [mark, setMark] = useState();
    const [markDownloading, setMarkDownloading] = useState(null);

    const fetchData = async () =>{
        
        return backend_config.makeRequest("GET", backend_config.AI_MODEL_VERSION_GET_LIST_BY_AI_MODEL_API.replace('{ai_model_id}', ai_model?.id), token)
        .then((response) => response.json())
        .then((data) => {return data; })
    }

    const fetchDataToTable = () =>{
        fetchData().then((data) => {
            setTableData(data);
            if (!isLoaded) setIsLoaded(true);
        });
    }

    const getDownloadURL = (ai_model_version_id) =>{
        return backend_config.makeRequest("GET", backend_config.COMMON_GET_DOWNLOAD_TOKEN, token)
        .then((response) => response.json())
        .then((download_token) => {
            window.open(backend_config.AI_MODEL_VERSION_DOWNLOAD_TRAINING_RESULT.replace(
                "{ai_model_version_id}", ai_model_version_id).replace("{download_token}", download_token));
        })
    }
    const getDownloadModelURL = (ai_model_version_id) =>{
        setMarkDownloading(ai_model_version_id);
        return backend_config.makeRequest("GET", backend_config.COMMON_GET_DOWNLOAD_TOKEN, token)
        .then((response) => response.json())
        .then((download_token) => {
            const url = window.open(backend_config.AI_MODEL_VERSION_DOWNLOAD_MODEL.replace(
                "{ai_model_version_id}", ai_model_version_id).replace("{download_token}", download_token));
            // console.log(url);
            // if (url) setMarkDownloading(null);
        })
    }

    const getCategories = (datasets) => {
        let categories = undefined;
        for (let i=0; i< datasets.length; i++){
            if (datasets[i]?.categories !== undefined) {
                categories = datasets[i]?.categories;
                break;
            }
        } 
        
        let values = [];
        for (let i=0; i< categories.length; i++){
            values.push(categories[i].name);
        } 
        return values;
    }


    const columns = [
        { 
            field: 'name', 
            headerName: langPack["Name"], 
            flex: 1,
        },
        {
            field: 'create_at',
            headerName: langPack["Create at"],
            flex: 1,
            // renderCell: (params)=>{

            //         return (
            //             <>
            //                 <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
            //             </>
            //           )
            //     }
        },
        {
            field: 'ai_model_rule.training_time',
            headerName: langPack["Training time"],
            flex: 1,
            valueGetter: (params) => {
                const starting_time = Date.parse(params.row?.training_result?.starting_time);
                const finishing_time = Date.parse(params.row?.training_result?.finishing_time);
                const seconds = (finishing_time - starting_time) / 1000;
                if (isNaN(seconds)) return '';
                else return `${seconds} ${langPack["seconds"]}`;
            }
        },
        // {
        //     field: 'categories',
        //     headerName: langPack["Categories"],
        //     flex: 1,
        //     valueGetter: (params) => {
        //         const datasets = params.row?.datasets;
        //         return getCategories(datasets);
        //     }
        // },
        {
            field: 'training_result.status',
            headerName: langPack["Status"],
            // flex: 1,
            valueGetter: (params) => params.row?.training_result?.status
        },
        {
            field: 'training_result.result',
            headerName: langPack["Result"],
            flex: 1,
            // width: 300,
            // valueGetter: (params) => params.row?.training_result?.result,
            renderCell: (params)=>{
                if (params.row?.training_result?.result !== null && params.row?.training_result?.result !== undefined && params.row?.training_result?.result !== "None")
                {
                    const value = params.row?.training_result?.result;
                    // console.log(params.colDef.computedWidth)
                    return (
                        <>
                            {/* <FileDownloadIcon className="ListDetail" 
                                onClick={() => getDownloadURL(params.row.id)}>
                            </FileDownloadIcon> */}
                            <GridCellExpand value={value || ''} width={params.colDef.computedWidth}/>
                        </>
                            // <p style={{ textAlign: "start" }}>
                            //     {value || ''}
                            // </p>
                                                
                      )
                }
            }
        },
        {
            field:"action",
            headerName: langPack["Action"],
            flex: 1,
            renderCell: (params)=>{
                const id = params.row.id;
                return(
                    <>
                        {/* <Link to={"/dashboard/cong-van-di/"+params.row.so_cong_van_di} params={{id: params.row.so_cong_van_di}}>
                            <button className='companyListEdit'>Details</button>
                        </Link> */}
                        {/* <InfoIcon className="ListDetail" onClick={()=>{setMark(id); setDetailAIModelVersionButtonPopup(!detailAIModelVersionButtonPopup)}}></InfoIcon> */}
                        <DetailAIModelVersion token={token} trigger={detailAIModelVersionButtonPopup}
                            setTrigger={setDetailAIModelVersionButtonPopup} ai_model_version={params.row}
                            refreshFunc={fetchDataToTable} categories={getCategories(params.row?.datasets)}>
                        </DetailAIModelVersion>

                        <DeleteOutline className='ListDelete' onClick={()=>{setMark(id); setDeleteAIModelVersionButtonPopup(true)}}>
                        </DeleteOutline>
                        <DeletePopup token={token} id={id} refreshFunc={fetchDataToTable} 
                            url={backend_config.AI_MODEL_VERSION_DELETE_BY_ID.replace('{ai_model_version_id}', id)} 
                            message={`Are you sure to delete version ${params.row?.name} ?`}
                            trigger={deleteAIModelVersionButtonPopup} setTrigger={setDeleteAIModelVersionButtonPopup}
                            markDelete={mark}
                        >
                        </DeletePopup>

                        <FileDownloadIcon className="ListDetail" 
                            onClick={() => {setMarkDownloading(id); setDownloadButtonPopup(!downloadButtonPopup)}}>
                        </FileDownloadIcon>
                        <Download token={token} id={id} mark={markDownloading} trigger={downloadButtonPopup} setTrigger={setDownloadButtonPopup}
                            isAvaiable={{
                                training_result: (params.row?.training_result?.result !== null && params.row?.training_result?.result !== undefined && params.row?.training_result?.result !== "None") ? true: false,
                                model: (params.row?.training_result?.status === "SUCCESS")? true: false,
                                training_log: (params.row?.training_result?.status !== "PENDING" && params.row?.training_result?.status !== "STARTED")? true: false,
                                // training_log: (params.row?.training_result?.result !== null && params.row?.training_result?.result !== undefined && params.row?.training_result?.result !== "None") ? true: false,
                            }}>

                        </Download>

                        {/* { markDownloading !== id ?
                            <FileDownloadIcon className="ListDetail" 
                                onClick={() => getDownloadModelURL(params.row.id)}>
                            </FileDownloadIcon> : <div className="loading-icon"></div>
                        } */}
                    </>

                )
            }
        }
    ];

    useEffect(() => {
        fetchDataToTable();
    }, [])
    
    return isLoaded ? (
        <div className='List'>
            <h2 className='ListTitle'>{langPack["All versions of model {ai_model?.name}"].replace("{ai_model?.name}", ai_model?.name)}</h2>
            <br></br>
            <div style={{ height: 'calc(90vh - 170px)', width: '100%' }}>
                <DataGrid
                    getRowId={(r) => r.id}
                    rows={tableData}
                    disableSelectionOnClick
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                //   checkboxSelection
                />
            </div>
            <Button
                        className='companyAddButton'
                        style={{
                            margin: '10px 10px 10px auto',
                            display: 'flex',
                            border: '1px solid #ff9b44',
                            backgroundColor: '#ff9b44',
                            borderRadius: '50px',
                            cursor: 'pointer',
                            color: 'white',
                            fontSize: '16px',
                            textTransform: 'inherit',
                        }}
                        startIcon={<Add/>}
                        onClick={() => setAddAIModelVersionButtonPopup(true)}
                    >
                        {langPack["Add new version"]}
                    </Button>
            <AddAIModelVersion token={token} refreshFunc={fetchDataToTable} ai_model_id={ai_model.id}
                trigger={addAIModelVersionButtonPopup} setTrigger={setAddAIModelVersionButtonPopup}>
            </AddAIModelVersion>
        </div>
    ): ""
}